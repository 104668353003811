export type ImageSizeTypes = 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large';

export  type ImagesSizeDef = {
    w: number;
    h: number;
    q: number;
};

export type ImagesSizesDef = Record<ImageSizeTypes, ImagesSizeDef>;

export const imageSizeTypes: ImageSizeTypes[] = [
    'x-small',
    'small',
    'medium',
    'large',
    'x-large',
    'xx-large'
] as const;

export const imageSizeTypesForProduct: ImageSizeTypes[] = [
    'x-small',
    'medium',
    'large',
    'x-large'
];

export const imageSizesDef: ImagesSizesDef = {
    'xx-large': {
        w: 3000,
        h: 3000,
        q: 90
    },
    'x-large': {
        w: 2400,
        h: 2400,
        q: 90
    },
    large: {
        w: 1800,
        h: 1800,
        q: 90
    },
    medium: {
        w: 1200,
        h: 1200,
        q: 80
    },
    small: {
        w: 600,
        h: 600,
        q: 80
    },
    'x-small': {
        w: 200,
        h: 200,
        q: 80
    }
} as const;
